import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import StaticHero from "@components/staticHero"
import Container from "@components/container"
import BgImageSection from "@components/narcolepsy/BgImageSection"
import PrimaryCta from "@components/PrimaryCta"
import Callout from "@components/narcolepsy/CallOut"
import GradientText from "@components/gradientText.js"
import { Heading2, sectionSm } from "@styles/ui"

// Assets
import studiedChart from "@images/what-is-xywav/clinical-trial-graphic.svg"
import studiedChartMobile from "@images/what-is-xywav/clinical-trial-graphic-mobile.svg"

const WhatIsXywavPage = ({ location, data }) => {
  return (
    <Layout location={location} exitModalColour="purple">
      <Seo
        title="What is XYWAV & How It Works | XYWAV®"
        description="Learn about XYWAV® (calcium, magnesium, potassium, and sodium oxybates) and how it helps treat the two narcolepsy symptoms, cataplexy and excessive daytime sleepiness. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <StaticHero
        eyebrow="What is XYWAV"
        gradient="purple-red"
        jumpLinks={[{ text: "Study Results", link: "#study-results" }]}
        footnotes={
          <>
            <span tw="flex text-[12px] leading-[17px] md:(text-[14px] leading-[19px])">
              <sup tw="top-[7px]">*</sup>XYWAV contains 131 mg of sodium at the
              maximum recommended nightly dose.
            </span>
          </>
        }
      >
        <div>
          XYWAV is the
          <br tw="md:hidden" /> first and only
          <br tw="md:hidden" /> low&#8209;sodium* oxybate treatment for
          cataplexy and/or excessive daytime sleepiness (EDS) in narcolepsy
        </div>
      </StaticHero>

      {/* Symptoms */}
      <BgImageSection
        imageData={data}
        mobileAspectRatio={"83%"}
        minHeightDesktop={"330px"}
        mdlgThinnerText
        isWhiteBg
        imageLeft
        altText="A man discussing his narcolepsy symptoms with a doctor"
      >
        <div tw="md:py-12">
          <h2 css={[tw`font-black mb-6 text-2xl md:text-3xl`]}>
            XYWAV, taken at night, is thought to work during sleep to help
            improve 2 common symptoms of narcolepsy during the day
            <sup>&dagger;</sup>
          </h2>
          <p tw="mb-6 mr-1 text-sm md:text-base">
            <span tw="font-black">
              XYWAV is the first and only low&#8209;sodium oxybate
            </span>{" "}
            approved by the FDA for treating cataplexy and/or EDS in people ages
            7 years and older with&nbsp;narcolepsy.
          </p>
          <p tw="mb-6 mr-1 text-sm md:text-base">
            XYWAV can be taken{" "}
            <span tw="font-black">
              in addition to daytime medications like stimulants
            </span>
            . Be sure to talk to your doctor about any medications you are
            taking or plan to&nbsp;take.
          </p>

          <ul tw="list-none text-[0.75rem] ml-1.5  mb-4 lg:mb-0">
            <li tw="-indent-1.5">
              <sup>&dagger;</sup>The exact way XYWAV works for the treatment of
              cataplexy and EDS in patients with narcolepsy is unknown.
            </li>
          </ul>
          <PrimaryCta
            url={"/narcolepsy/dosing/"}
            addedStyles={tw`block max-w-[12rem] mx-auto mt-4 md:(max-w-[12rem] mx-[unset] mt-7)`}
          >
            Taking XYWAV
          </PrimaryCta>
        </div>
      </BgImageSection>

      {/* How was XYWAV studied */}
      <section css={[sectionSm, tw`bg-lilac`]} id="study-results">
        <Container>
          <h2
            css={[
              Heading2,
              tw`mb-6 mx-3 text-left lg:(text-center) before:(block mb-6 h-1.5 rounded-md gradientRed w-[68px]  mx-auto lg:(w-[124px]))`,
            ]}
          >
            How was XYWAV studied?
          </h2>

          <p tw="mb-6 mx-auto text-left text-2xl normal-case font-black text-center lg:(leading-[27px] max-w-[65rem])">
            The efficacy and safety of XYWAV was studied in 201 adults with
            narcolepsy with cataplexy who had at least 14 cataplexy attacks in a
            typical 2&#8209;week period before they took any treatment.
            <sup>&Dagger;&sect;</sup>
          </p>
          <p tw="text-base mx-auto lg:(text-center)">
            All people in the clinical trial started treatment with XYWAV. The
            majority of participants (59%) continued taking their stimulant or
            wake&#8209;promoting agent along with&nbsp;XYWAV.
          </p>

          <div tw="mt-6 md:(mt-[25px]) lg:(white-glow-gradient-vertical rounded-[25px] pt-[50px] pb-[25px] px-[70px])">
            <div tw="text-center">
              <img
                tw="hidden mx-auto md:(min-w-full) lg:block"
                src={studiedChart}
                alt="Graphic showing how XYWAV® was studied in a 2-week clinical trial"
              />
              <img
                tw="mx-auto max-w-[320px] w-full lg:hidden"
                src={studiedChartMobile}
                alt="Graphic showing how XYWAV® was studied in a 2-week clinical trial"
              />
            </div>
            <div tw="text-[0.75rem] text-left mt-2 ml-2.5 lg:(mx-auto max-w-[960px] mt-4 text-center)">
              <ul tw="list-none">
                <li tw="-indent-1.5">
                  <sup>&Dagger;</sup>Study included people aged 18-70.
                </li>
                <li tw="-indent-1.5 mt-[5px] lg:mt-2.5">
                  <sup>&sect;</sup>Not everyone completed each part of the
                  trial. Of the 201 patients, 134 were included in the 2-week
                  period that measured how well XYWAV worked.
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </section>

      <section tw="py-[30px] lg:py-[50px]">
        <Container>
          <div tw="before:(gradientRed block relative w-[124px] mb-[15px] h-1.5 rounded-md left-1/2 transform -translate-x-1/2) md:before:(mb-[25px]) xl:before:(mb-6)"></div>
          <h2
            css={[
              Heading2,
              tw`text-center font-bold mx-2 mb-5 col-span-full xl:(mb-6)`,
            ]}
          >
            Results
          </h2>

          <div tw="flex justify-center">
            <GradientText
              otherStyles={tw`mb-[15px] text-center lg:(mb-[25px] max-w-[55rem])`}
            >
              From the end of the 2 weeks where people in the clinical study
              kept taking XYWAV to the end of the 2 weeks of the study that
              measured how well XYWAV worked...
            </GradientText>
          </div>

          <div tw="flex flex-col justify-center items-center gap-[25px] lg:(flex-row)">
            <div tw="text-center border-gradient-small-rounded py-[25px] px-[20px] lg:(p-[25px])">
              <h3 tw="font-black text-[22px] leading-[28px] mb-[10px]">
                In people who <span tw="underline">stopped</span> taking XYWAV
              </h3>
              <p tw="mb-[10px]">
                Cataplexy increased by an{" "}
                <strong>average of 11.5 attacks per week</strong>
              </p>
              <p>
                Daytime sleepiness increased by an{" "}
                <strong>average&nbsp;of&nbsp;3</strong>
              </p>
            </div>

            <div tw="text-center border-gradient-small-rounded py-[25px] px-[20px] lg:(p-[25px])">
              <h3 tw="font-black text-[22px] leading-[28px] mb-[10px]">
                In people who <span tw="underline">continued</span> taking XYWAV
              </h3>
              <p tw="mb-[10px]">
                Cataplexy increased by an{" "}
                <strong>average of 0.1 attacks per week</strong>
              </p>
              <p>
                Daytime sleepiness increased by an{" "}
                <strong>average&nbsp;of&nbsp;0</strong>
              </p>
            </div>
          </div>

          <div tw="flex justify-center mt-[25px]">
            <PrimaryCta
              url={"/narcolepsy/talk-to-your-doctor/"}
              width={tw`md:max-w-[14rem]`}
            >
              Ask about XYWAV
            </PrimaryCta>
          </div>
        </Container>
      </section>

      <Callout
        theme="purple"
        heading={
          <span css={[Heading2]}>
            New to oxybate treatment and wondering if it may be right for you?
          </span>
        }
      >
        <PrimaryCta
          url={"/narcolepsy/talk-to-your-doctor/#quiz"}
          width={tw`md:max-w-[12rem]`}
        >
          Take the Quiz
        </PrimaryCta>
      </Callout>
    </Layout>
  )
}

export default WhatIsXywavPage

export const query = graphql`
  query {
    colImage: file(relativePath: { eq: "what-is-xywav/symptoms.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, placeholder: NONE)
      }
    }
    bgImageSection: file(
      relativePath: { eq: "what-is-xywav/man-with-doctor.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 960, placeholder: NONE)
      }
    }
    bgImageSectionMobile: file(
      relativePath: { eq: "what-is-xywav/man-with-doctor-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
  }
`
